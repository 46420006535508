.svg-fill-logo {
  fill: currentColor;
}

.svg-stroke-logo {
  stroke: currentColor;
}

.svg-fill-logo, .svg-stroke-logo {
  margin-right: 8px;
}

.heading-with-line {
  color: $dark-blue;
  text-align: center;
  padding-right: 11px;
  position: relative;

  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: .5px;
    display: inline-block;
    padding: 10px 20px;
    background: $white;
    margin-bottom: 0;
  }

  &.full {
    padding-right: 11px;

    &:after {
      right: 12px;
    }
  }

  &.small {
    padding-right: 0;

    h2 {
      font-size: 15px;
    }

    &:after {
      right: 0;
      top: 34px;
    }
  }

  &:after {
    content: ' ';
    opacity: none;
    position: absolute;
    left: 0;
    right: 11px;
    top: 35px;
    z-index: -9999999;
    border-bottom: 2px solid $dark-blue;
  }
}

.gray-box {
  border: 1px solid $box-gray;
  border-radius: 6px;
  overflow: hidden;

  &.box-shadow {
    -webkit-transition: all 300ms;
    transition: all 300ms;

    &:hover {
      box-shadow: 5px 5px 10px -7px #666;
      border-color: theme-color('deansoft-secondary');
    }
  }
}

.file-upload-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;

  .upload-link {
    display: inline-block;
    color: theme-color('fundra-primary');
    text-decoration: none;
    font-size: $font-size-base * .9;
    font-weight: 500;

    svg {
      width: 12px;
      height: 12px;
      margin-bottom: 2px;
      margin-right: 5px;
    }
  }

  input[type=file] {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
  }
}

.form-group > {
  label {
    font-weight: 500;
    letter-spacing: .5px;
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .input-group {
    .currency-input {
      -moz-appearance: textfield;
      &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
}

.table-gray {
  width: 100%;

  thead {
    tr {
      background-color: rgba(201, 211, 220, 0.18000000715255737);

      th {
        padding: 8px 20px;
        color: $table-header-color;
        text-transform: uppercase;
        font-size: $font-size-base * 0.8;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(201, 211, 220, 0.18000000715255737);
    }

    td {
      font-size: $font-size-base * 0.86;
      padding: 10px 20px;
      vertical-align: top;

      &.action-column {
        width: 70px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: center;

        &.md {
          width: 90px;
        }

        .action {
          display: -webkit-box;
          display: flex;
          -webkit-box-pack: space-around;
                  justify-content: space-around;

          button {
            outline: none;
            border: none;
            background: none;
            padding: 0;

            &.approve {
              color: theme-color('deansoft-success');
            }

            &.subscribe {
              color: theme-color('deansoft-success');
            }

            &.send-mail {
              color: theme-color('deansoft-secondary');
            }

            &.danger {
              color: theme-color('deansoft-danger');
            }

            &.edit {
              color: $table-header-color;
              .svg-fill-logo {
                width: 16px;
                height: 16px;
                margin-right: 0;
              }
            }

            &.approve, &.subscribe, &.send-mail, &.danger {
              .svg-fill-logo {
                width: 17px;
                height: 19px;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }
}

.table-gray-hover {
  tr {
    &:hover {
      cursor: pointer;
      background-color: $table-hover;
    }
  }
}

.submit-button-modal {
  .spinner-border {
    margin-right: 8px;
    margin-bottom: 2px;
  }
}

.custom-action-dropdown {
  font-size: $font-size-base * 0.95;

  a {
    &:focus {
      outline: none;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
  }

  .dropdown-menu {
    padding: 0.3rem;

    .dropdown-item {
      text-align: left;
      border-radius: 0.25rem;
      font-size: $font-size-base * 0.95;
      outline: none;
      padding-left: 0.5rem;
      padding-right: 0.5rem;

      &.danger {
        color: #D0021B;
      }

      &.active {
        background-color: theme-color('deansoft-primary');
      }

      &:hover {
        font-weight: 500;
        color: theme-color('deansoft-primary');
        background-color: rgba(99,158,254,.16);
      }
    }
  }

  &.small {
    font-size: $font-size-base * 0.879;

    .icon {
      width: 13px;
      height: 13px;
    }

    .dropdown-menu {
      .dropdown-item {
        font-size: $font-size-base * 0.879;
      }
    }
  }
}

.alert-swal {
  font-size: $font-size-base;
}

.swal-title {
  font-size: $font-size-base * 1.5;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.21);
  margin-top: 5px!important;
  margin-bottom: 28px!important;
}

.swal-text {
  text-align: center;
  color: $body-color; //#61534e;
  font-size: $font-size-base;
}

.swal-button {
  padding-top: 7px;
  padding-bottom: 7px;
}

.swal-footer {
  background-color: rgb(245, 248, 250);
  margin-top: 32px;
  border-top: 1px solid #E9EEF1;
  overflow: hidden;
  text-align: center;
}

.outline-badge {
  border: 1px solid theme-color('deansoft-primary');
  color: theme-color('deansoft-primary');
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: inline-block;
  min-width: 54px;
  text-align: center;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &.outline-badge-danger {
    border: 1px solid $outline-danger;
    color: theme-color('deansoft-secondary-danger');
    background-color: $deansoft-bg-secondary-danger;

    &:hover {
      background-color: $outline-danger;
      color: #fff;
    }
  }

  &.outline-badge-success {
    border: 1px solid $outline-success;
    color: theme-color('deansoft-secondary-success');
    background-color: $deansoft-bg-secondary-success;

    &:hover {
      background-color: $outline-success;
      color: #fff;
    }
  }

  &.outline-badge-warning {
    border: 1px solid $outline-warning;
    color: theme-color('deansoft-secondary-warning');
    background-color: $deansoft-bg-secondary-warning;

    &:hover {
      background-color: $outline-warning;
      color: #fff;
    }
  }
}

.header-break {
  border-bottom: 1px solid $break-line;
}

.required {
  font-size: 80%;
  color: theme-color('deansoft-secondary-danger');
  margin-left: 2px;
}

.form-footer-note {
  font-size: 90%;
  padding-left: 15px;
  font-style: italic;

  &.no-pad {
    padding-left: 0px;
  }
}

.outline-gray-button {
  font-size: $font-size-base * 0.95;

  &.text-small {
    font-size: $font-size-base * 0.9;
  }

  .icon {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: theme-color('secondary');
    border-color: theme-color('secondary');
    color: #fff;
  }

  &.small {
    font-size: $font-size-base * 0.87;

    .icon {
      width: 13px;
      height: 13px;
    }
  }
}

.bullet {
  border-radius: 100%;
  width: 8px;
  height: 8px;
  margin-right: 7px;

  &.bullet-success {
    background-color: $bullet-success;
  }

  &.bullet-primary {
    background-color: $bullet-primary;
  }

  &.bullet-warning {
    background-color: $bullet-warning;
  }

  &.bullet-secondary {
    background-color: $bullet-secondary;
  }

  &.bullet-danger {
    background-color: $bullet-danger;
  }

  &.bullet-info {
    background-color: $bullet-info;
  }

  &.bullet-violet {
    background-color: $bullet-violet;
  }
}

.gray-border-top {
  border-top: 1px solid $box-gray;
}

.gray-border-right {
  border-right: 1px solid $box-gray;
}

.color-pallet {
  border: none;
  outline: none;
  border-radius: 4px;
  height: 45px;
  margin-right: 7px;
  color: #fff;

  svg {
    margin-right: 0;
  }

  &:focus {
    outline: none;
  }
}

.tag-color {
  border-radius: 10px;
  height: 10px;
}

.color-pallet, .tag-color {

  width: 45px;

  &.color-green {
    background-color: theme-color('deansoft-green');
  }

  &.color-blue {
    background-color: theme-color('deansoft-blue');
  }

  &.color-yellow {
    background-color: theme-color('deansoft-yellow');
  }

  &.color-violet {
    background-color: theme-color('deansoft-violet');
  }

  &.color-danger {
    background-color: theme-color('deansoft-danger');
  }

  &.color-smoke {
    background-color: theme-color('deansoft-smoke');
  }
}

.edit-icon-button {
  width: 26px;
  height: 26px;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 100%;
  box-shadow: -1px 2px 6px -3px #666;
  background-color: #fff;
  color: theme-color('deansoft-danger');
  text-align: center;
  visibility: hidden;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:focus, &:active {
    outline: none;
  }

  &:hover {
    box-shadow: -1px 2px 11px -2px #666;
  }

  &.show {
    visibility: visible;
  }

  svg {
    margin-right: 0;
    margin-bottom: 4px;
    width: 13px;
    height: 13px;
  }
}

.action-icon-button {
  width: 20px;
  height: 26px;
  padding: 5px 0px;
  border: none;
  outline: none;
  color: theme-color('deansoft-danger');
  text-align: center;
  background-color: #fff;

  &.hidden {
    display: none;
  }

  &:focus, &:active {
    outline: none;
  }

  svg {
    margin-right: 0;
    margin-bottom: 4px;
    width: 13px;
    height: 13px;
  }
}

.progress-bar-success {
  .progress-bar {
    background-color: $bullet-success;
  }
}

.progress-bar-primary {
  .progress-bar {
    background-color: $bullet-primary;
  }
}

.progress-bar-warning {
  .progress-bar {
    background-color: $bullet-warning;
  }
}

.progress-bar-secondary {
  .progress-bar {
    background-color: $bullet-secondary;
  }
}

.progress-bar-danger {
  .progress-bar {
    background-color: $bullet-danger;
  }
}

.progress-bar-info {
  .progress-bar {
    background-color: $bullet-info;
  }
}

.progress-bar-violet {
  .progress-bar {
    background-color: $bullet-violet;
  }
}

.button-reset {
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  text-align: left;

  &:focus {
    outline: none;
  }
}

.form-transition-enter {
  opacity: 0;
}
.form-transition-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in-out;
}
.form-transition-exit {
  opacity: 1;
}
.form-transition-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}

.overlay-bg {
  width: 100%;
  height: 100%;
  position: absolute;

  &.overlay-white {
    background-color: rgba(255,255,255,.9);
  }
}

@include media-breakpoint-down(md) {
  .gray-border-right {
    border-right: none;
  }
}
